$color: (
	typography: (
		1: #FFFFFF,
		2: #FF0000,
		3: #000000,
		4: #F2F2F2,
		5: #eff2f5,
		6: #3c4452,
		7: #4882b4,
		8: #2c2c64,
		9: #494949,
		10: #39393c,
		11: #f9f9f9,
		12: #21252b,
		13: #111112

	),
	bg: (
		1: #FFFFFF,
		2: #FF0000,
		3: #000000,
		4: #F2F2F2,
		5: #eff2f5,
		6: #3c4452,
		7: #4882b4,
		8: #2c2c64,
		9: #494949,
		10: #39393c,
		11: #f9f9f9,
		12: #21252b,
		13: #111112
		

	)
);


$font__family: (
    primary: '"Open Sans", sans-serif',
    base: '"Big Shoulders Display", cursive',

);


$font__weight: (
	light: 300, 	// font__weight(light)
	regular: 400, 	// font__weight(regular)
	medium: 500,	// font__weight(medium)
	semibold: 600,	// font__weight(semi-bold)
	bold: 700		// font__weight(bold)
);










