.stellarnav,
.stellarnav li {
    position: relative;
    line-height: normal
}

.stellarnav {
    width: 100%;
    z-index: 9900
}

.stellarnav a {
    color: #777
}

.stellarnav ul {
    margin: 0;
    padding: 0;
    text-align: center
}

.stellarnav li {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    vertical-align: middle
}

.stellarnav li a {
    // padding: 15px;
    // display: block;
    // text-decoration: none;
    // color: #777;
    // font-size: inherit;
    // font-family: inherit;
    // box-sizing: border-box;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.stellarnav.light a,
.stellarnav.light li a {
    color: #000
}

.stellarnav>ul>li {
    display: inline-block
}

.stellarnav>ul>li>a {
    // padding: 20px 40px
}

.stellarnav ul ul {
    top: auto;
    width: 220px;
    position: absolute;
    z-index: 9900;
    text-align: left;
    display: none;
    background: #ddd
}

.stellarnav.light,
.stellarnav.light ul ul {
    background: rgba(255, 255, 255, 1)
}

.stellarnav li li {
    display: block
}

.stellarnav ul ul ul {
    top: 0;
    left: 220px
}

.stellarnav>ul>li:hover>ul>li:hover>ul {
    opacity: 1;
    visibility: visible;
    top: 0
}

.stellarnav>ul>li.drop-left>ul {
    right: 0
}

.stellarnav li.drop-left ul ul {
    left: auto;
    right: 220px
}

.stellarnav.dark,
.stellarnav.dark ul ul {
    background: #ffffff;
    
}

.stellarnav.dark a,
.stellarnav.dark li a {
    color: #FFF
}

.stellarnav.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999
}

body.stellarnav-noscroll-x {
    overflow-x: hidden
}

.stellarnav li.has-sub>a:after {
    content: '';
    margin-left: 5px;
    // border-left: 6px solid transparent;
    // border-right: 6px solid transparent;
    // border-top: 6px solid #FFF;
    // display: inline-block
}

.stellarnav .dd-toggle,
.stellarnav li.call-btn-mobile,
.stellarnav li.location-btn-mobile,
.stellarnav.hide-arrows li li.has-sub>a:after,
.stellarnav.hide-arrows li.drop-left li.has-sub>a:after,
.stellarnav.hide-arrows li.has-sub>a:after {
    display: none
}

.stellarnav li li.has-sub>a:after {
    // margin-left: 10px;
    float: right;
    // border-top: 6px solid transparent;
    // border-bottom: 6px solid transparent;
    // border-left: 6px solid #FFF;
    position: relative;
    top: 4px
}

.stellarnav li.drop-left li.has-sub>a:after {
    float: left;
    margin-right: 10px;
    border-left: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #FFF
}

.stellarnav .call-btn-mobile,
.stellarnav .close-menu,
.stellarnav .location-btn-mobile,
.stellarnav .menu-toggle {
    display: none;
    text-transform: uppercase;
    text-decoration: none
}

.stellarnav .dd-toggle {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    // width: 48px;
    // height: 48px;
    text-align: center;
    z-index: 9999;
    border: 0
}

.stellarnav.desktop li.has-sub a {
    // padding-right: 5px
}

.stellarnav.desktop.hide-arrows li.has-sub a {
    padding-right: 15px
}

.stellarnav.mobile>ul>li>a.dd-toggle {
    padding: 0
}

.stellarnav svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
    position: relative;
    top: 2px
}

.stellarnav a.dd-toggle .icon-plus {
    box-sizing: border-box;
    transition: transform .3s;
    width: 12px;
    height: 100%;
    position: relative;
    vertical-align: middle;
    display: inline-block
}

.stellarnav a.dd-toggle .icon-plus:after,
.stellarnav a.dd-toggle .icon-plus:before {
    content: '';
    display: block;
    height: 0;
    top: 50%;
    border-bottom: solid 3px #777;
    position: absolute;
    width: 12px
}

.stellarnav a.dd-toggle .icon-plus:before {
    transform: rotate(90deg);
    transition: width .3s
}

.stellarnav li.open>a.dd-toggle .icon-plus {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg)
}

.stellarnav.light a.dd-toggle .icon-plus:after,
.stellarnav.light a.dd-toggle .icon-plus:before {
    border-color: #000
}

.stellarnav.dark a.dd-toggle .icon-plus:after,
.stellarnav.dark a.dd-toggle .icon-plus:before {
    border-color: #FFF
}

.stellarnav .icon-close {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    position: relative;
    display: inline-block
}

.stellarnav .icon-close:after,
.stellarnav .icon-close:before {
    content: '';
    display: block;
    width: 12px;
    height: 0;
    top: 50%;
    border-bottom: solid 3px #777;
    position: absolute
}

.stellarnav .icon-close:before {
    transform: rotate(45deg)
}

.stellarnav .icon-close:after {
    transform: rotate(-45deg)
}

.stellarnav.light .icon-close:after,
.stellarnav.light .icon-close:before {
    border-color: #000
}

.stellarnav.dark .icon-close:after,
.stellarnav.dark .icon-close:before {
    border-color: #FFF
}

.stellarnav .call-btn-mobile,
.stellarnav .close-menu,
.stellarnav .location-btn-mobile,
.stellarnav .menu-toggle {
    padding: 15px;
    box-sizing: border-box
}

.stellarnav .menu-toggle span.bars {
    display: inline-block;
    position: relative;
    top: 3px
}

.stellarnav .menu-toggle span.bars span {
    display: block;
    width: 30px;
    height: 2px;
    background: #000;
}
.stellarnav .menu-toggle span.bars span:not(:last-child) {
    margin-bottom: 8px;
}

.stellarnav .full {
    width: 100%
}

.stellarnav .half {
    width: 50%
}

.stellarnav .third {
    width: 33%;
    text-align: center
}

.stellarnav .location-btn-mobile.third {
    text-align: center
}

.stellarnav .location-btn-mobile.half {
    text-align: right
}

.stellarnav.light .half,
.stellarnav.light .third {
    border-left: 1px solid rgba(0, 0, 0, .15)
}

.stellarnav.light.left .half,
.stellarnav.light.left .third,
.stellarnav.light.right .half,
.stellarnav.light.right .third {
    border-bottom: 1px solid rgba(0, 0, 0, .15)
}

.stellarnav.light .half:first-child,
.stellarnav.light .third:first-child {
    border-left: 0
}

.stellarnav.dark .half,
.stellarnav.dark .third {
    border-left: 1px solid rgba(255, 255, 255, .15)
}

.stellarnav.dark.left .half,
.stellarnav.dark.left .third,
.stellarnav.dark.right .half,
.stellarnav.dark.right .third {
    border-bottom: 1px solid rgba(255, 255, 255, .15)
}

.stellarnav.dark.left .menu-toggle,
.stellarnav.dark.right .menu-toggle,
.stellarnav.light.left .menu-toggle,
.stellarnav.light.right .menu-toggle {
    border-bottom: 0
}

.stellarnav.dark .half:first-child,
.stellarnav.dark .third:first-child {
    border-left: 0
}

.stellarnav.light .menu-toggle span.bars span {
    background: #000
}

.stellarnav.dark .menu-toggle span.bars span {
    background: #000
}

.stellarnav.mobile,
.stellarnav.mobile.fixed {
    position: static
}

.stellarnav.mobile ul {
    position: relative;
    display: none;
    text-align: left;
    background: rgba(221, 221, 221, 1)
}

.stellarnav.mobile.active>ul,
.stellarnav.mobile>ul>li {
    display: block
}

.stellarnav.mobile.active {
    padding-bottom: 0
}

.stellarnav.mobile>ul>li>a {
    padding: 10px 15px;
    display: block;
}

.stellarnav.mobile ul ul {
    position: relative;
    opacity: 1;
    visibility: visible;
    width: auto;
    display: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    transition: none;
}

.stellarnav.mobile ul ul ul {
    left: auto;
    top: auto;
}

.stellarnav.mobile li.drop-left ul ul {
    right: auto;
}

.stellarnav.mobile li a {
    border-bottom: 1px solid rgba(255, 255, 255, .15);
}

.stellarnav.mobile>ul {
    border-top: 1px solid rgba(255, 255, 255, .15);
}

.stellarnav.mobile.light li a {
    border-bottom: 1px solid rgba(0, 0, 0, .15);
}

.stellarnav.mobile.light>ul {
    border-top: 1px solid rgba(0, 0, 0, .15);
}

.stellarnav.mobile li a.dd-toggle,
.stellarnav.mobile.light li a.dd-toggle {
    border: 0;
}

.stellarnav.mobile .call-btn-mobile,
.stellarnav.mobile .close-menu,
.stellarnav.mobile .dd-toggle,
.stellarnav.mobile .location-btn-mobile,
.stellarnav.mobile .menu-toggle {
    display: inline-block;
}

.stellarnav.mobile li.call-btn-mobile {
    border-right: 1px solid rgba(255, 255, 255, .1);
    box-sizing: border-box;
}

.stellarnav.mobile li.call-btn-mobile,
.stellarnav.mobile li.location-btn-mobile {
    display: inline-block;
    width: 50%;
    text-transform: uppercase;
    text-align: center;
}

.stellarnav.mobile li.call-btn-mobile.full,
.stellarnav.mobile li.location-btn-mobile.full {
    display: block;
    width: 100%;
    text-transform: uppercase;
    border-right: 0;
    text-align: left;
}

.stellarnav.mobile.light ul {
    background: rgba(255, 255, 255, 1);
}

.stellarnav.mobile.dark ul {
    background-color: #37a9cd;
}

.stellarnav.mobile.dark ul ul {
    // background: rgba(255, 255, 255, .08);
}

.stellarnav.mobile.light li.call-btn-mobile {
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.stellarnav.mobile.top {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
}

.stellarnav.mobile li li.has-sub>a:after,
.stellarnav.mobile li.drop-left li.has-sub>a:after,
.stellarnav.mobile li.has-sub>a:after {
    display: none;
}

.stellarnav.mobile.left>ul,
.stellarnav.mobile.right>ul {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.stellarnav.mobile.right>ul {
    right: 0;
}

.stellarnav.mobile.left .close-menu,
.stellarnav.mobile.right .close-menu {
    display: inline-block;
    text-align: right;
}

.stellarnav.mobile.left>ul {
    left: 0;
}

.stellarnav.mobile.left .call-btn-mobile.half,
.stellarnav.mobile.left .call-btn-mobile.third,
.stellarnav.mobile.left .close-menu.half,
.stellarnav.mobile.left .close-menu.third,
.stellarnav.mobile.left .location-btn-mobile.half,
.stellarnav.mobile.left .location-btn-mobile.third,
.stellarnav.mobile.right .call-btn-mobile.half,
.stellarnav.mobile.right .call-btn-mobile.third,
.stellarnav.mobile.right .close-menu.half,
.stellarnav.mobile.right .close-menu.third,
.stellarnav.mobile.right .location-btn-mobile.half,
.stellarnav.mobile.right .location-btn-mobile.third {
    text-align: center;
}

.stellarnav.mobile.left .menu-toggle.half,
.stellarnav.mobile.left .menu-toggle.third,
.stellarnav.mobile.right .menu-toggle.half,
.stellarnav.mobile.right .menu-toggle.third {
    text-align: left;
}

.stellarnav.mobile.left .close-menu.third span,
.stellarnav.mobile.right .close-menu.third span {
    display: none;
}

.stellarnav.desktop li.mega ul ul {
    background: 0 0;
    width: auto;
}

.stellarnav.desktop li.mega li {
    display: inline-block;
    vertical-align: top;
    margin-left: -4px;
}

.stellarnav.desktop li.mega li li {
    display: block;
    position: relative;
    left: 4px;
}

.stellarnav.desktop>ul>li.mega {
    position: inherit;
}

.stellarnav.desktop>ul>li.mega>ul {
    width: 100%;
}

.stellarnav.desktop>ul>li.mega>ul li.has-sub ul {
    display: block;
    position: relative;
    left: auto;
}

.stellarnav.desktop>ul>li.mega>ul>li {
    padding-bottom: 15px;
    box-sizing: border-box;
}

.stellarnav.desktop li.mega li li a {
    padding: 5px 15px;
}

.stellarnav.desktop li.mega li.has-sub a:after {
    display: none;
}

.stellarnav.desktop>ul>li.mega>ul>li>a {
    color: #ff0;
}

@media only screen and (max-width :768px) {
    .stellarnav {
        overflow: hidden;
        display: block;
    }

    .stellarnav ul {
        position: relative;
        display: none;
    }
}

@media only screen and (max-width :420px) {

    .stellarnav.mobile .call-btn-mobile.third span,
    .stellarnav.mobile .location-btn-mobile.third span {
        display: none;
    }
}

