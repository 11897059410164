.section {
    &-hero {
        background-color: color(typography, 1);
        position: relative;
        .overlay {
            background-color: rgba(color(typography, 3), 0.3);
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 1;
        }
        .hero-img {
            width: 100%;
            @media(max-width: 991px) {
                height: 450px;
                object-fit: cover;
            }
        }
        .hero-content {
            background-color: rgba(color(typography, 7), 0.8);
            color: color(typography, 1);
            position: absolute;
            top: 0;
            height: 100%;
            // width: 30%;
            // width: 32%;
            display: flex;
            align-items: center;
            padding: 40px;
            z-index: 5;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            @media(min-width: 1200px) {
                padding-bottom: 100px;
            }
            
            h2 {
                font-weight: 700;
                margin-bottom: 20px;
                @media(min-width: 1200px) {
                    line-height: 54px;
                }
            }
            
            .btn {
                margin-top: 20px;
            }
        }

    }
}

.inner-hero {
    background-size: cover;
    position: relative;
    &:before {
      background-color: rgba(color(typography, 3), .5);
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &-wrapper {
      min-height: 460px;
      min-height: 230px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: color(typography,1);
      position: relative;
      z-index: 5;
      h1 {
        color: color(typography,1);
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }