@charset "UTF-8";
.rotate {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

label {
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out; }

.navbar-toggler span {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

button {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  button:after {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

.btn {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  .btn:hover {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

a {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  a:hover {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

input[type="radio"] + label {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  input[type="radio"] + label::before {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  input[type="radio"] + label::after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

input[type="checkbox"] + label {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  input[type="checkbox"] + label:before {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  input[type="checkbox"] + label:after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
  background: #fff url(../images/ajax-loader.gif) center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url(../fonts/slick.eot);
  src: url(../fonts/slick.eot?#iefix) format("embedded-opentype"), url(../fonts/slick.woff) format("woff"), url(../fonts/slick.ttf) format("truetype"), url(../fonts/slick.svg#slick) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

body {
  color: #39393c;
  background-color: #FFFFFF;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden; }

.img-100 {
  width: 100%; }

small {
  font-size: 14px; }

b,
strong {
  font-weight: bold; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style: none; }

ul.list-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  ul.list-inline li {
    display: inline-block; }

img {
  display: block;
  max-width: 100%;
  height: auto; }
  img.img-center {
    margin-left: auto;
    margin-right: auto; }
  img.full-width {
    width: 100%; }

a {
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none;
    color: inherit; }

.btn {
  text-decoration: none; }
  .btn:hover {
    text-decoration: none; }

a:hover::before, a:hover::after {
  text-decoration: none; }

a:focus {
  outline: none; }

.btn:focus {
  outline: none; }

.btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: inherit; }

p {
  margin: 0 0 1rem 0; }
  p:empty {
    display: none; }
  p:last-child {
    margin-bottom: 0; }

.screen-reader-text {
  display: none; }

h1 {
  font-size: 40px;
  font-weight: 600; }

h2 {
  font-size: 30px;
  font-weight: 600; }

h3 {
  font-size: 24px;
  font-weight: 600; }

h4 {
  font-size: 20px;
  font-weight: 600; }

.site-content {
  overflow-x: hidden; }

.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

img {
  display: block;
  max-width: 100%;
  height: auto; }
  img .aligncenter {
    float: none;
    margin: 0 auto 20px; }
  img .alignright {
    float: right;
    margin: 0 0 20px 20px; }
  img .alignleft {
    float: left;
    margin: 0 20px 20px 0; }

.slick-slide:focus {
  outline: none; }

.btn {
  border-radius: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 20px;
  padding-left: 28px;
  padding-right: 28px; }
  .btn-white {
    background-color: #FFFFFF;
    color: #4882b4;
    border: 1px solid #4882b4; }
    .btn-white:hover {
      background-color: #4882b4;
      color: #FFFFFF; }
  .btn-blue {
    background-color: #2c2c64;
    color: #FFFFFF;
    border: 1px solid #2c2c64; }
    .btn-blue:hover {
      background-color: #FFFFFF;
      color: #2c2c64; }

.stellarnav,
.stellarnav li {
  position: relative;
  line-height: normal; }

.stellarnav {
  width: 100%;
  z-index: 9900; }

.stellarnav a {
  color: #777; }

.stellarnav ul {
  margin: 0;
  padding: 0;
  text-align: center; }

.stellarnav li {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

.stellarnav li a {
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  transition: all .3s ease-out; }

.stellarnav.light a,
.stellarnav.light li a {
  color: #000; }

.stellarnav > ul > li {
  display: inline-block; }

.stellarnav ul ul {
  top: auto;
  width: 220px;
  position: absolute;
  z-index: 9900;
  text-align: left;
  display: none;
  background: #ddd; }

.stellarnav.light,
.stellarnav.light ul ul {
  background: white; }

.stellarnav li li {
  display: block; }

.stellarnav ul ul ul {
  top: 0;
  left: 220px; }

.stellarnav > ul > li:hover > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0; }

.stellarnav > ul > li.drop-left > ul {
  right: 0; }

.stellarnav li.drop-left ul ul {
  left: auto;
  right: 220px; }

.stellarnav.dark,
.stellarnav.dark ul ul {
  background: #ffffff; }

.stellarnav.dark a,
.stellarnav.dark li a {
  color: #FFF; }

.stellarnav.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999; }

body.stellarnav-noscroll-x {
  overflow-x: hidden; }

.stellarnav li.has-sub > a:after {
  content: '';
  margin-left: 5px; }

.stellarnav .dd-toggle,
.stellarnav li.call-btn-mobile,
.stellarnav li.location-btn-mobile,
.stellarnav.hide-arrows li li.has-sub > a:after,
.stellarnav.hide-arrows li.drop-left li.has-sub > a:after,
.stellarnav.hide-arrows li.has-sub > a:after {
  display: none; }

.stellarnav li li.has-sub > a:after {
  float: right;
  position: relative;
  top: 4px; }

.stellarnav li.drop-left li.has-sub > a:after {
  float: left;
  margin-right: 10px;
  border-left: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #FFF; }

.stellarnav .call-btn-mobile,
.stellarnav .close-menu,
.stellarnav .location-btn-mobile,
.stellarnav .menu-toggle {
  display: none;
  text-transform: uppercase;
  text-decoration: none; }

.stellarnav .dd-toggle {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  text-align: center;
  z-index: 9999;
  border: 0; }

.stellarnav.desktop.hide-arrows li.has-sub a {
  padding-right: 15px; }

.stellarnav.mobile > ul > li > a.dd-toggle {
  padding: 0; }

.stellarnav svg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  position: relative;
  top: 2px; }

.stellarnav a.dd-toggle .icon-plus {
  box-sizing: border-box;
  transition: transform .3s;
  width: 12px;
  height: 100%;
  position: relative;
  vertical-align: middle;
  display: inline-block; }

.stellarnav a.dd-toggle .icon-plus:after,
.stellarnav a.dd-toggle .icon-plus:before {
  content: '';
  display: block;
  height: 0;
  top: 50%;
  border-bottom: solid 3px #777;
  position: absolute;
  width: 12px; }

.stellarnav a.dd-toggle .icon-plus:before {
  transform: rotate(90deg);
  transition: width .3s; }

.stellarnav li.open > a.dd-toggle .icon-plus {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg); }

.stellarnav.light a.dd-toggle .icon-plus:after,
.stellarnav.light a.dd-toggle .icon-plus:before {
  border-color: #000; }

.stellarnav.dark a.dd-toggle .icon-plus:after,
.stellarnav.dark a.dd-toggle .icon-plus:before {
  border-color: #FFF; }

.stellarnav .icon-close {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  position: relative;
  display: inline-block; }

.stellarnav .icon-close:after,
.stellarnav .icon-close:before {
  content: '';
  display: block;
  width: 12px;
  height: 0;
  top: 50%;
  border-bottom: solid 3px #777;
  position: absolute; }

.stellarnav .icon-close:before {
  transform: rotate(45deg); }

.stellarnav .icon-close:after {
  transform: rotate(-45deg); }

.stellarnav.light .icon-close:after,
.stellarnav.light .icon-close:before {
  border-color: #000; }

.stellarnav.dark .icon-close:after,
.stellarnav.dark .icon-close:before {
  border-color: #FFF; }

.stellarnav .call-btn-mobile,
.stellarnav .close-menu,
.stellarnav .location-btn-mobile,
.stellarnav .menu-toggle {
  padding: 15px;
  box-sizing: border-box; }

.stellarnav .menu-toggle span.bars {
  display: inline-block;
  position: relative;
  top: 3px; }

.stellarnav .menu-toggle span.bars span {
  display: block;
  width: 30px;
  height: 2px;
  background: #000; }

.stellarnav .menu-toggle span.bars span:not(:last-child) {
  margin-bottom: 8px; }

.stellarnav .full {
  width: 100%; }

.stellarnav .half {
  width: 50%; }

.stellarnav .third {
  width: 33%;
  text-align: center; }

.stellarnav .location-btn-mobile.third {
  text-align: center; }

.stellarnav .location-btn-mobile.half {
  text-align: right; }

.stellarnav.light .half,
.stellarnav.light .third {
  border-left: 1px solid rgba(0, 0, 0, 0.15); }

.stellarnav.light.left .half,
.stellarnav.light.left .third,
.stellarnav.light.right .half,
.stellarnav.light.right .third {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

.stellarnav.light .half:first-child,
.stellarnav.light .third:first-child {
  border-left: 0; }

.stellarnav.dark .half,
.stellarnav.dark .third {
  border-left: 1px solid rgba(255, 255, 255, 0.15); }

.stellarnav.dark.left .half,
.stellarnav.dark.left .third,
.stellarnav.dark.right .half,
.stellarnav.dark.right .third {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15); }

.stellarnav.dark.left .menu-toggle,
.stellarnav.dark.right .menu-toggle,
.stellarnav.light.left .menu-toggle,
.stellarnav.light.right .menu-toggle {
  border-bottom: 0; }

.stellarnav.dark .half:first-child,
.stellarnav.dark .third:first-child {
  border-left: 0; }

.stellarnav.light .menu-toggle span.bars span {
  background: #000; }

.stellarnav.dark .menu-toggle span.bars span {
  background: #000; }

.stellarnav.mobile,
.stellarnav.mobile.fixed {
  position: static; }

.stellarnav.mobile ul {
  position: relative;
  display: none;
  text-align: left;
  background: #dddddd; }

.stellarnav.mobile.active > ul,
.stellarnav.mobile > ul > li {
  display: block; }

.stellarnav.mobile.active {
  padding-bottom: 0; }

.stellarnav.mobile > ul > li > a {
  padding: 10px 15px;
  display: block; }

.stellarnav.mobile ul ul {
  position: relative;
  opacity: 1;
  visibility: visible;
  width: auto;
  display: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: color 0 ease-in;
  transition: none; }

.stellarnav.mobile ul ul ul {
  left: auto;
  top: auto; }

.stellarnav.mobile li.drop-left ul ul {
  right: auto; }

.stellarnav.mobile li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15); }

.stellarnav.mobile > ul {
  border-top: 1px solid rgba(255, 255, 255, 0.15); }

.stellarnav.mobile.light li a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

.stellarnav.mobile.light > ul {
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.stellarnav.mobile li a.dd-toggle,
.stellarnav.mobile.light li a.dd-toggle {
  border: 0; }

.stellarnav.mobile .call-btn-mobile,
.stellarnav.mobile .close-menu,
.stellarnav.mobile .dd-toggle,
.stellarnav.mobile .location-btn-mobile,
.stellarnav.mobile .menu-toggle {
  display: inline-block; }

.stellarnav.mobile li.call-btn-mobile {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box; }

.stellarnav.mobile li.call-btn-mobile,
.stellarnav.mobile li.location-btn-mobile {
  display: inline-block;
  width: 50%;
  text-transform: uppercase;
  text-align: center; }

.stellarnav.mobile li.call-btn-mobile.full,
.stellarnav.mobile li.location-btn-mobile.full {
  display: block;
  width: 100%;
  text-transform: uppercase;
  border-right: 0;
  text-align: left; }

.stellarnav.mobile.light ul {
  background: white; }

.stellarnav.mobile.dark ul {
  background-color: #37a9cd; }

.stellarnav.mobile.light li.call-btn-mobile {
  border-right: 1px solid rgba(255, 255, 255, 0.1); }

.stellarnav.mobile.top {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999; }

.stellarnav.mobile li li.has-sub > a:after,
.stellarnav.mobile li.drop-left li.has-sub > a:after,
.stellarnav.mobile li.has-sub > a:after {
  display: none; }

.stellarnav.mobile.left > ul,
.stellarnav.mobile.right > ul {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.stellarnav.mobile.right > ul {
  right: 0; }

.stellarnav.mobile.left .close-menu,
.stellarnav.mobile.right .close-menu {
  display: inline-block;
  text-align: right; }

.stellarnav.mobile.left > ul {
  left: 0; }

.stellarnav.mobile.left .call-btn-mobile.half,
.stellarnav.mobile.left .call-btn-mobile.third,
.stellarnav.mobile.left .close-menu.half,
.stellarnav.mobile.left .close-menu.third,
.stellarnav.mobile.left .location-btn-mobile.half,
.stellarnav.mobile.left .location-btn-mobile.third,
.stellarnav.mobile.right .call-btn-mobile.half,
.stellarnav.mobile.right .call-btn-mobile.third,
.stellarnav.mobile.right .close-menu.half,
.stellarnav.mobile.right .close-menu.third,
.stellarnav.mobile.right .location-btn-mobile.half,
.stellarnav.mobile.right .location-btn-mobile.third {
  text-align: center; }

.stellarnav.mobile.left .menu-toggle.half,
.stellarnav.mobile.left .menu-toggle.third,
.stellarnav.mobile.right .menu-toggle.half,
.stellarnav.mobile.right .menu-toggle.third {
  text-align: left; }

.stellarnav.mobile.left .close-menu.third span,
.stellarnav.mobile.right .close-menu.third span {
  display: none; }

.stellarnav.desktop li.mega ul ul {
  background: 0 0;
  width: auto; }

.stellarnav.desktop li.mega li {
  display: inline-block;
  vertical-align: top;
  margin-left: -4px; }

.stellarnav.desktop li.mega li li {
  display: block;
  position: relative;
  left: 4px; }

.stellarnav.desktop > ul > li.mega {
  position: inherit; }

.stellarnav.desktop > ul > li.mega > ul {
  width: 100%; }

.stellarnav.desktop > ul > li.mega > ul li.has-sub ul {
  display: block;
  position: relative;
  left: auto; }

.stellarnav.desktop > ul > li.mega > ul > li {
  padding-bottom: 15px;
  box-sizing: border-box; }

.stellarnav.desktop li.mega li li a {
  padding: 5px 15px; }

.stellarnav.desktop li.mega li.has-sub a:after {
  display: none; }

.stellarnav.desktop > ul > li.mega > ul > li > a {
  color: #ff0; }

@media only screen and (max-width: 768px) {
  .stellarnav {
    overflow: hidden;
    display: block; }
  .stellarnav ul {
    position: relative;
    display: none; } }

@media only screen and (max-width: 420px) {
  .stellarnav.mobile .call-btn-mobile.third span,
  .stellarnav.mobile .location-btn-mobile.third span {
    display: none; } }

.header-site {
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }

.header-top {
  background-color: #eff2f5;
  padding-top: 8px;
  padding-bottom: 9px;
  font-size: 13px;
  position: relative; }
  .header-top ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-end; }
    @media (max-width: 767px) {
      .header-top ul {
        justify-content: center; } }
    .header-top ul li {
      padding-left: 10px;
      padding-right: 10px;
      color: #39393c; }
      .header-top ul li a {
        color: #39393c; }

.logo_wrap {
  background-color: #FFFFFF;
  padding: 20px 0; }
  .logo_wrap .row {
    align-items: center; }
  @media (max-width: 767px) {
    .logo_wrap .logo {
      display: none; } }
  .logo_wrap .logo img {
    display: inline-block; }
    @media (max-width: 1199px) {
      .logo_wrap .logo img {
        width: 100px; } }
  .logo_wrap .logo a {
    color: #4882b4;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase; }
    @media (max-width: 1199px) {
      .logo_wrap .logo a {
        font-size: 14px; } }
    .logo_wrap .logo a b {
      color: #2c2c64; }
  .logo_wrap .enquire ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }
    @media (min-width: 768px) {
      .logo_wrap .enquire ul {
        justify-content: flex-end; } }
    @media (max-width: 767px) {
      .logo_wrap .enquire ul {
        justify-content: center;
        margin-left: -5px;
        margin-right: -5px; } }
    .logo_wrap .enquire ul li {
      padding-left: 10px;
      padding-right: 10px; }
      @media (max-width: 767px) {
        .logo_wrap .enquire ul li {
          padding-left: 5px;
          padding-right: 5px;
          margin-bottom: 1px;
          margin-top: 1px; } }
      .logo_wrap .enquire ul li a {
        background-color: #4882b4;
        color: #FFFFFF;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid #4882b4;
        padding: .375rem 1.25rem;
        font-size: 1rem;
        line-height: 1.5;
        text-transform: uppercase; }
        @media (max-width: 1199px) {
          .logo_wrap .enquire ul li a {
            font-size: 13px; } }
        .logo_wrap .enquire ul li a:hover {
          background-color: #FFFFFF;
          color: #4882b4; }
        .logo_wrap .enquire ul li a i {
          margin-right: 10px; }

.navbar[class*="navbar-expand-"] {
  background-color: #3c4452;
  padding: 0;
  transition: all 1s ease-in-out; }
  @media (max-width: 767px) {
    .navbar[class*="navbar-expand-"] {
      background-color: #FFFFFF;
      border-top: 1px solid rgba(0, 0, 0, 0.3); } }
  @media (max-width: 767px) {
    .navbar[class*="navbar-expand-"] {
      padding-left: 15px;
      padding-right: 15px; } }
  .navbar[class*="navbar-expand-"] .navbar-toggler {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer; }
    .navbar[class*="navbar-expand-"] .navbar-toggler:focus {
      outline: none; }
    .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon {
      background: #000000;
      display: block;
      height: 3px;
      width: 100%;
      border-radius: 0;
      opacity: 1;
      left: 0;
      margin-bottom: 6px;
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -ms-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out; }
      .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(1) {
        top: 0px; }
      .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(2), .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(3) {
        top: 10px;
        top: 8px; }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(1) {
      top: 11px;
      width: 0%;
      left: 50%; }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(2) {
      transform: rotate(45deg); }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(3) {
      transform: rotate(-45deg);
      margin-top: -8px; }
  .navbar[class*="navbar-expand-"] .navbar-brand {
    padding-top: 13px;
    padding-bottom: 13px; }
    @media (min-width: 1200px) {
      .navbar[class*="navbar-expand-"] .navbar-brand img {
        max-width: 250px; } }
    @media (min-width: 768px) {
      .navbar[class*="navbar-expand-"] .navbar-brand {
        display: none; } }
  .navbar[class*="navbar-expand-"] .navbar-nav li {
    transition: all 1s ease; }
    @media (min-width: 992px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li:not(:last-child) {
        margin-right: 20px; } }
    @media (min-width: 1200px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li:not(:last-child) {
        margin-right: 52px; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li a:not(.dd-toggle) {
      color: #FFFFFF;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 16px;
      padding: 8px 20px; }
      .navbar[class*="navbar-expand-"] .navbar-nav li a:not(.dd-toggle):not(.dd-toggle) {
        display: block; }
      .navbar[class*="navbar-expand-"] .navbar-nav li a:not(.dd-toggle):focus {
        outline: none; }
      .navbar[class*="navbar-expand-"] .navbar-nav li a:not(.dd-toggle):hover {
        background-color: #4882b4;
        color: #FFFFFF; }
    .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle:after {
      content: '\f107';
      border: none;
      font-family: 'FontAwesome';
      vertical-align: middle; }
      @media (max-width: 991px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle:after {
          display: none; } }
    @media (max-width: 991px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle .glyphicon {
        display: none; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu,
    .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu {
      position: absolute;
      padding: 0;
      border-radius: 0; }
      .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu li,
      .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu li {
        margin-right: 0; }
        .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu li a,
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu li a {
          padding: 10px 15px;
          display: block;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children {
      position: relative; }
      @media (min-width: 992px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown:hover:after, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children:hover:after {
          color: #FF0000; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
        color: #000000;
        font-size: 11px; }
        @media (min-width: 992px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
            display: none; } }
        @media (max-width: 991px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
            position: absolute;
            right: 0;
            width: 32px;
            right: 0;
            height: 32px;
            line-height: 32px;
            top: 0;
            text-align: center; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown:hover .sub-menu, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children:hover .sub-menu {
        display: block; }
  @media (max-width: 991px) {
    .navbar[class*="navbar-expand-"].open {
      background-color: #FFFFFF; } }
  .navbar[class*="navbar-expand-"] .stellarnav {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center; }
    .navbar[class*="navbar-expand-"] .stellarnav .menu-toggle {
      position: absolute;
      right: 0; }
    .navbar[class*="navbar-expand-"] .stellarnav .navbar-nav li.has-sub > a:after {
      content: '\f107';
      font-family: 'FontAwesome'; }
    .navbar[class*="navbar-expand-"] .stellarnav .navbar-nav li.has-sub ul {
      background-color: #4882b4; }
      .navbar[class*="navbar-expand-"] .stellarnav .navbar-nav li.has-sub ul li {
        margin-right: 0; }
        .navbar[class*="navbar-expand-"] .stellarnav .navbar-nav li.has-sub ul li a {
          padding: 8px 12px;
          display: block; }
          @media (min-width: 992px) {
            .navbar[class*="navbar-expand-"] .stellarnav .navbar-nav li.has-sub ul li a {
              border-bottom: 1px solid rgba(255, 255, 255, 0.1); } }
          .navbar[class*="navbar-expand-"] .stellarnav .navbar-nav li.has-sub ul li a.dd-toggle {
            border-bottom: none; }
    .navbar[class*="navbar-expand-"] .stellarnav.mobile ul li a {
      padding: 10px 15px;
      display: block;
      color: #FFFFFF; }
      .navbar[class*="navbar-expand-"] .stellarnav.mobile ul li a.dd-toggle {
        padding: 6px 15px; }

body.home-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-home > a,
body.contact-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-contact > a,
body.about-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-about > a,
body.gallery-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-gallery > a,
body.course-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-course > a {
  background-color: #4882b4; }

.section-title {
  font-size: 15px; }
  .section-title h2 {
    color: #2c2c64;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px; }
    .section-title h2:before {
      background-color: #4882b4;
      content: '';
      position: absolute;
      bottom: 0;
      height: 3px;
      left: 0;
      right: 0;
      max-width: 125px;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }

.section-welcome {
  background-color: #f9f9f9;
  padding-top: 60px;
  padding-bottom: 40px; }
  .section-welcome .content__wrap {
    line-height: 2;
    line-height: 32px; }
    @media (max-width: 991px) {
      .section-welcome .content__wrap {
        margin-bottom: 30px; } }
    .section-welcome .content__wrap h2 {
      font-weight: 700;
      color: #2c2c64;
      margin-bottom: 20px; }
    .section-welcome .content__wrap p strong {
      font-weight: 400;
      color: #2c2c64;
      font-size: 20px; }
    .section-welcome .content__wrap .btn {
      margin-top: 15px;
      font-size: 18px;
      font-weight: 400; }
  .section-welcome .image__wrap {
    position: relative;
    padding-top: 25px;
    padding-right: 25px; }
    @media (min-width: 1200px) {
      .section-welcome .image__wrap {
        padding-left: 40px; } }
    .section-welcome .image__wrap:before, .section-welcome .image__wrap:after {
      content: '';
      background-color: #4882b4;
      position: absolute; }
    .section-welcome .image__wrap:before {
      right: 0;
      width: 80%;
      top: 0;
      height: 25px; }
    .section-welcome .image__wrap:after {
      right: 0;
      width: 25px;
      top: 0;
      height: 100%; }
    .section-welcome .image__wrap img {
      width: 100%; }

.section-career {
  background-color: #FFFFFF;
  padding-top: 60px;
  padding-bottom: 60px; }
  .section-career .career__wrap {
    margin-top: 40px; }
  .section-career .career__image img {
    width: 100%; }
  .section-career .career__content {
    background-color: #f9f9f9;
    padding: 10px 10px 0 10px; }
  .section-career .career__title {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: 20px; }
    .section-career .career__title > div {
      padding-left: 10px;
      padding-right: 10px; }
    .section-career .career__title .title {
      width: calc(100% - 100px); }
    .section-career .career__title .price {
      width: 100px;
      text-align: right;
      color: #2c2c64; }
  .section-career .button-wrap ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .section-career .button-wrap ul li {
      max-width: 46%;
      flex: 0 0 46%; }
      .section-career .button-wrap ul li a.btn {
        font-size: 16px;
        transform: skewX(35deg);
        padding-left: 20px;
        padding-right: 20px;
        display: block; }
        @media (max-width: 767px) {
          .section-career .button-wrap ul li a.btn {
            font-size: 11px; } }
        .section-career .button-wrap ul li a.btn span {
          transform: skewX(-35deg);
          display: block; }
      .section-career .button-wrap ul li a.btn-blueLight {
        background-color: #4882b4;
        border: 1px solid #4882b4;
        color: #FFFFFF; }
        .section-career .button-wrap ul li a.btn-blueLight:hover {
          background-color: #FFFFFF;
          color: #4882b4; }
      .section-career .button-wrap ul li a.btn-white {
        background-color: transparent;
        border: 1px solid transparent;
        color: #2c2c64;
        margin-left: -15px; }
        .section-career .button-wrap ul li a.btn-white:hover {
          color: #4882b4; }
      .section-career .button-wrap ul li a.btn-grey {
        background-color: #494949;
        border: 1px solid #494949;
        color: #FFFFFF; }
        .section-career .button-wrap ul li a.btn-grey:hover {
          background-color: #FFFFFF;
          color: #494949; }
    .section-career .button-wrap ul + ul {
      justify-content: flex-end; }

.section-about {
  padding-top: 40px;
  padding-bottom: 50px; }
  .section-about .about__image {
    margin-bottom: 30px; }
  .section-about .about__content {
    margin-bottom: 30px; }

.section-workFlow {
  background-color: #f8f8f8;
  padding: 50px 0; }
  @media (max-width: 991px) {
    .section-workFlow .workFlow__left {
      margin-bottom: 30px; } }
  .section-workFlow .workFlow__left h2 {
    color: #4882b4;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 40px;
    position: relative;
    margin-bottom: 40px; }
    .section-workFlow .workFlow__left h2:after {
      content: '';
      background-image: url(../images/titleborder-rt.png);
      left: auto;
      right: 0;
      position: absolute;
      height: 32px;
      width: 32px;
      background-size: cover;
      background-repeat: no-repeat;
      top: 10px; }
  .section-workFlow .workFlow__left ul li {
    margin-bottom: 10px; }
    .section-workFlow .workFlow__left ul li a {
      color: currentColor;
      position: relative;
      padding-bottom: 3px; }
      .section-workFlow .workFlow__left ul li a:before {
        content: '';
        background-color: #4882b4;
        position: absolute;
        left: 50%;
        right: 50%;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: all 0.2s ease-in-out; }
      .section-workFlow .workFlow__left ul li a:hover:before {
        width: 100%;
        left: 0; }
  .section-workFlow .workFlow__right h2 {
    color: #4882b4;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 700; }
  .section-workFlow .workFlow__right ul li {
    position: relative;
    padding-left: 24px;
    margin-bottom: 8px; }
    .section-workFlow .workFlow__right ul li:before {
      content: '';
      background-color: #FFFFFF;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.9);
      left: 0;
      display: inline-block;
      position: absolute;
      top: 8px; }

.section-projects {
  padding-top: 60px;
  padding-bottom: 30px; }
  .section-projects .project__wrap {
    margin-bottom: 30px; }
    .section-projects .project__wrap figure {
      margin-bottom: 0;
      height: 350px;
      background-size: cover;
      background-position: center; }
      .section-projects .project__wrap figure a {
        display: block;
        height: 100%;
        width: 100%; }
  .section-projects .project__title {
    padding: 10px 10px 0 10px;
    border: 1px solid #ddd;
    text-align: center; }
    .section-projects .project__title h3 {
      font-size: 18px;
      font-weight: 600; }
      .section-projects .project__title h3 a {
        color: currentColor; }
        .section-projects .project__title h3 a:hover {
          color: #eff2f5; }

.section-contact {
  padding-top: 60px;
  padding-bottom: 60px; }
  .section-contact .contact__info {
    margin-bottom: 40px; }
    .section-contact .contact__info h2 {
      font-size: 20px;
      padding-bottom: 6px;
      margin-bottom: 20px;
      border-bottom: 2px dotted rgba(0, 0, 0, 0.5); }
    .section-contact .contact__info ul li {
      margin-bottom: 15px; }
      .section-contact .contact__info ul li strong {
        display: block;
        margin-bottom: 5px; }
      .section-contact .contact__info ul li a {
        color: #000000; }
  .section-contact .contact__map {
    margin-top: 30px; }
    .section-contact .contact__map h2 {
      font-size: 20px;
      padding-bottom: 6px;
      margin-bottom: 20px;
      border-bottom: 2px dotted rgba(0, 0, 0, 0.5); }
    .section-contact .contact__map iframe {
      height: 520px;
      width: 100%; }
  @media (max-width: 767px) {
    .section-contact .contact__form {
      margin-top: 30px; } }
  .section-contact .contact__form h2 {
    font-size: 20px;
    padding-bottom: 6px;
    margin-bottom: 20px;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.5); }
  .section-contact .contact__form .form-control {
    border-radius: 0; }
    .section-contact .contact__form .form-control:focus {
      box-shadow: none; }
  .section-contact .contact__form .code-sec {
    margin-top: 20px;
    max-width: 200px; }
  .section-contact .contact__form .btn-default {
    border-radius: 0;
    background-color: #4882b4;
    color: #FFFFFF;
    margin-top: 20px;
    min-width: 200px;
    text-transform: uppercase; }
    .section-contact .contact__form .btn-default:hover {
      background-color: #2c2c64; }

.section-course {
  padding: 60px 0 40px 0; }
  .section-course .course__wrap h3 {
    color: #2c2c64; }
  .section-course .course__wrap .book-now ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end; }
    .section-course .course__wrap .book-now ul li:not(:last-child) {
      margin-right: 15px; }
  .section-course .course__content {
    margin-bottom: 30px; }
  .section-course .course__info {
    background-color: #F2F2F2;
    padding: 15px 20px;
    margin-bottom: 15px; }
    .section-course .course__info li:not(:last-child) {
      margin-bottom: 10px; }
    .section-course .course__info li span {
      display: inline-block; }
      .section-course .course__info li span.title {
        position: relative; }
        @media (min-width: 768px) {
          .section-course .course__info li span.title {
            min-width: 150px; } }
        @media (max-width: 767px) {
          .section-course .course__info li span.title {
            font-weight: 600; } }
        .section-course .course__info li span.title:after {
          content: ':'; }
          @media (min-width: 768px) {
            .section-course .course__info li span.title:after {
              float: right; } }
      @media (min-width: 768px) {
        .section-course .course__info li span.desc {
          padding-left: 30px; } }
  .section-course .course__faq h3 {
    margin-bottom: 20px; }
  .section-course .course__faq .accordion {
    margin-bottom: 20px; }
    .section-course .course__faq .accordion .card {
      margin-bottom: 5px;
      border-radius: 0;
      border: 1px solid rgba(0, 0, 0, 0.125); }
      .section-course .course__faq .accordion .card-header {
        padding: 7px 15px; }
        .section-course .course__faq .accordion .card-header .btn {
          padding: 0;
          display: block;
          width: 100%;
          text-align: left;
          font-size: 16px; }
          .section-course .course__faq .accordion .card-header .btn:after {
            content: '\f106';
            font-family: 'FontAwesome';
            float: right; }
          .section-course .course__faq .accordion .card-header .btn.collapsed:after {
            content: '\f107';
            font-family: 'FontAwesome'; }

.section-duration {
  background-color: rgba(242, 242, 242, 0.5);
  border-top: 1px solid #F2F2F2;
  padding: 60px 0; }
  .section-duration .book-now {
    margin-right: 20px;
    margin-left: 15px; }
    .section-duration .book-now .btn {
      background-color: #494949;
      border: 1px solid #494949;
      color: #FFFFFF;
      font-size: 16px;
      -webkit-transform: skewX(35deg);
      transform: skewX(35deg);
      padding-left: 20px;
      padding-right: 20px; }
      .section-duration .book-now .btn span {
        -webkit-transform: skewX(-35deg);
        transform: skewX(-35deg);
        display: block; }
      .section-duration .book-now .btn:hover {
        background-color: #FFFFFF;
        color: #494949; }

.book-now {
  margin-right: 20px;
  margin-left: 15px; }
  .book-now .btn {
    background-color: #494949;
    border: 1px solid #494949;
    color: #FFFFFF;
    font-size: 16px;
    -webkit-transform: skewX(35deg);
    transform: skewX(35deg);
    padding-left: 20px;
    padding-right: 20px; }
    .book-now .btn span {
      -webkit-transform: skewX(-35deg);
      transform: skewX(-35deg);
      display: block; }
    .book-now .btn:hover {
      background-color: #FFFFFF;
      color: #494949; }

.form-control {
  font-size: 14px; }
  .form-control:focus {
    box-shadow: none; }

.payment__wrap button[type="submit"] {
  width: 100%; }

.payment__wrap .payments ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px; }
  .payment__wrap .payments ul li {
    margin-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
    flex: 0 0 100%; }
    .payment__wrap .payments ul li img {
      margin-left: auto;
      margin-right: auto;
      max-width: 230px; }

.footer__top {
  background-color: #21252b;
  color: #c5c5c5;
  font-size: 13px;
  padding-top: 30px;
  padding-bottom: 60px; }
  .footer__top [class*="col-"] {
    margin-bottom: 20px; }
  .footer__top a {
    color: #c5c5c5; }
  .footer__top h3 {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #c5c5c5; }
    .footer__top h3:before {
      background-color: #c5c5c5;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 300px;
      width: 100%;
      height: 1px;
      z-index: 1; }
    .footer__top h3:after {
      background-color: #4882b4;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 115px;
      width: 100%;
      height: 1px;
      z-index: 2; }
  .footer__top .courseoffer ul li {
    position: relative;
    padding-left: 20px; }
    .footer__top .courseoffer ul li:not(:last-child) {
      margin-bottom: 12px; }
    .footer__top .courseoffer ul li:before {
      content: '';
      height: 10px;
      width: 10px;
      border: 1px solid #c5c5c5;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 3px; }
  .footer__top .footer-contact ul li {
    position: relative;
    padding-left: 24px;
    line-height: 35px; }
    .footer__top .footer-contact ul li:before {
      position: absolute;
      font-family: 'FontAwesome';
      left: 0;
      font-size: 14px; }
    .footer__top .footer-contact ul li.phone:before {
      content: '\f095'; }
    .footer__top .footer-contact ul li.mailto:before {
      content: '\f0e0'; }
    .footer__top .footer-contact ul li.location:before {
      content: '\f041'; }

.footer__copyright {
  background-color: #111112;
  color: #c5c5c5;
  padding-top: 37px;
  padding-bottom: 37px;
  font-size: 13px; }
  .footer__copyright .copyright a {
    color: #c5c5c5; }
  .footer__copyright .poweredBy a {
    color: #1a588b; }

.section-hero {
  background-color: #FFFFFF;
  position: relative; }
  .section-hero .overlay {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1; }
  .section-hero .hero-img {
    width: 100%; }
    @media (max-width: 991px) {
      .section-hero .hero-img {
        height: 450px;
        object-fit: cover; } }
  .section-hero .hero-content {
    background-color: rgba(72, 130, 180, 0.8);
    color: #FFFFFF;
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 40px;
    z-index: 5;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px; }
    @media (min-width: 1200px) {
      .section-hero .hero-content {
        padding-bottom: 100px; } }
    .section-hero .hero-content h2 {
      font-weight: 700;
      margin-bottom: 20px; }
      @media (min-width: 1200px) {
        .section-hero .hero-content h2 {
          line-height: 54px; } }
    .section-hero .hero-content .btn {
      margin-top: 20px; }

.inner-hero {
  background-size: cover;
  position: relative; }
  .inner-hero:before {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1; }
  .inner-hero-wrapper {
    min-height: 460px;
    min-height: 230px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #FFFFFF;
    position: relative;
    z-index: 5; }
    .inner-hero-wrapper h1 {
      color: #FFFFFF;
      font-weight: 700;
      text-transform: uppercase; }
