.header {
    &-site {
        position: relative;
        box-shadow: 0 0 15px rgba(0, 0, 0, .3);
    }

    &-top {
        background-color: color(typography, 5);
        padding-top: 8px;
        padding-bottom: 9px;
        font-size: 13px;
        position: relative;

        ul {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
            justify-content: flex-end;
            

            @media(max-width: 767px) {
                justify-content: center;
            }

            li {
                padding-left: 10px;
                padding-right: 10px;
                color: color(typography, 10);

                a {
                    color: color(typography, 10);
                }
            }
        }
    }

}
.logo_wrap {
    background-color: color(typography, 1);
    padding: 20px 0;
    .row {
        align-items: center;
    }
    .logo {
        @media(max-width: 767px) {
            display: none;
        }
        img {
            display: inline-block;
            @media(max-width: 1199px) {
                width: 100px;
            }
            
        }
        a {
            color: color(typography, 7);
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
            @media(max-width: 1199px) {
                font-size: 14px;
            }
            b {
                color: color(typography, 8); 
            }
        }
    }
    .enquire {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
            @media(min-width: 768px) {
                justify-content: flex-end;
            }
            @media(max-width: 767px) {
                justify-content: center;
                margin-left: -5px;
                margin-right: -5px;
            }
            li {
                padding-left: 10px;
                padding-right: 10px;
                @media(max-width: 767px) {
                    padding-left: 5px;
                    padding-right: 5px;
                    margin-bottom: 1px;
                    margin-top: 1px;
                }
                a {
                    background-color: color(typography, 7);
                    color: color(typography, 1);
                    display: inline-block;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    border: 1px solid color(typography, 7);
                    padding: .375rem 1.25rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    text-transform: uppercase;
                    @media(max-width: 1199px) {
                        font-size: 13px;
                    }
                    &:hover {
                        background-color: color(typography, 1);
                        color: color(typography, 7);
                    }
                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.navbar {
    &[class*="navbar-expand-"] {
        background-color: color(bg, 6);
        padding: 0;
        @media(max-width: 767px) {
            background-color: color(bg, 1);
            border-top: 1px solid rgba(color(typography, 3), .3);
        }

        transition: all 1s ease-in-out;

        @media(max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .navbar-toggler {
            width: 30px;
            height: 20px;
            position: relative;
            margin: 0;
            padding: 0;
            outline: none;
            border: none;
            @include transition(.5s ease-in-out);
            cursor: pointer;

            &:focus {
                outline: none;
            }

            .navbar-toggle-icon {
                background: color(typography, 3);
                display: block;
                // position: absolute;
                height: 3px;
                width: 100%;
                border-radius: 0;
                opacity: 1;
                left: 0;
                margin-bottom: 6px;
                transform: rotate(0deg);
                @include transition(.25s ease-in-out);

                @media(max-width: 767px) {
                    // background-color: color(bg, 3);
                }

                &:nth-child(1) {
                    top: 0px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 10px;
                    top: 8px;
                }
            }

            &.on {
                .navbar-toggle-icon {
                    &:nth-child(1) {
                        top: 11px;
                        width: 0%;
                        left: 50%;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                        margin-top: -8px;

                    }

                }
            }
        }

        .navbar {
            &-brand {
                padding-top: 13px;
                padding-bottom: 13px;

                img {
                    @media(min-width: 1200px) {
                        max-width: 250px
                    }
                }
                @media(min-width: 768px) {
                    display: none;
                }

            }

            &-nav {

                li {
                    transition: all 1s ease;

                    @media(max-width: 991px) {
                        // margin-bottom: 10px;
                    }

                    &:not(:last-child) {
                        @media(min-width: 992px) {
                            margin-right: 20px;
                        }

                        @media(min-width: 1200px) {
                            margin-right: 52px;
                        }
                    }

                    a:not(.dd-toggle) {
                        color: color(typography, 1);
                        text-transform: uppercase;
                        letter-spacing: 0.02em;
                        font-size: 16px;
                        padding: 8px 20px;
                        // display: block;
                        &:not(.dd-toggle) {
                            display: block;
                        }

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            background-color: color(typography, 7);
                            color: color(typography, 1);
                        }
                    }

                    .dropdown-toggle {
                        &:after {
                            content: '\f107';
                            border: none;
                            font-family: 'FontAwesome';
                            vertical-align: middle;

                            @media(max-width: 991px) {
                                display: none;
                            }
                        }

                        .glyphicon {
                            @media(max-width: 991px) {
                                display: none;
                            }

                        }
                    }


                    .sub-menu,
                    .dropdown-menu {
                        position: absolute;
                        padding: 0;
                        border-radius: 0;

                        li {
                            margin-right: 0;

                            a {
                                padding: 10px 15px;
                                display: block;
                                border-bottom: 1px solid rgba(color(typography, 3), 0.15);
                            }
                        }
                    }

                    &.dropdown,
                    &.menu-item-has-children {
                        position: relative;

                        &:after {
                            // @media(max-width: 991px) {
                            //     content: '\f078';
                            //     font-family: "FontAwesome";
                            //     color: color(typography, 3);
                            //     font-size: 11px;
                            // }

                        }

                        &:hover {
                            &:after {
                                @media(min-width: 992px) {
                                    color: color(typography, 2);
                                }
                            }
                        }



                        .drop-toggler {
                            color: color(typography, 3);
                            font-size: 11px;

                            @media(min-width: 992px) {
                                display: none;
                            }

                            @media(max-width: 991px) {

                                position: absolute;
                                right: 0;
                                width: 32px;
                                right: 0;
                                height: 32px;
                                line-height: 32px;
                                top: 0;
                                // padding-left: 10px;
                                // padding-right: 10px;
                                text-align: center;
                            }
                        }

                        &:hover {
                            .sub-menu {
                                display: block;
                            }
                        }
                    }

                }


            }

        }

        &.open {
            @media(max-width: 991px) {
                background-color: color(typography, 1);
            }
        }
        .stellarnav {
            background-color: transparent;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            align-items: center;
            .menu-toggle {
                position: absolute;
                right: 0;
            }
            
            .navbar-nav {
                li {
                    &.has-sub {
                       > a:after {
                           content: '\f107';
                           font-family:'FontAwesome';
                       }
                       ul {
                           background-color: color(typography, 7);
                        @media(min-width: 992px) {
                            // border: 1px solid rgba(color(typography, 3), .1);
                            // margin-top: 5px;
                        }
                           li {
                               margin-right: 0;
                               a {
                                   padding: 8px 12px;
                                   display: block;
                                   @media(min-width: 992px) {
                                    border-bottom: 1px solid rgba(color(typography, 1), .1);
                                   }
                                   &.dd-toggle {
                                       border-bottom: none;
                                   }
                               }
                           }
                       }
                    }
                }
            }
            &.mobile {
                 ul {
                     li {
                         a {
                            padding: 10px 15px;
                            display: block;
                            color: color(typography, 1);
                            &.dd-toggle {
                                padding: 6px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

body.home-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-home > a,
body.contact-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-contact > a,
body.about-page .navbar[class*="navbar-expand-"] .navbar-nav li.nav-about > a,
body.gallery-page .navbar[class*="navbar-expand-"] .navbar-nav  li.nav-gallery > a,
body.course-page .navbar[class*="navbar-expand-"] .navbar-nav  li.nav-course > a {
    background-color: color(typography, 7);
}
